import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { lastValueFrom } from 'rxjs';

/**
 * Interfejs reprezentujący element aplikacji.
 */
interface AppElement {
  /** Nazwa elementu */
  name: string;
  /** Typ elementu */
  type: string;
  /** Adres URL elementu */
  href: string;
  /** Ikona elementu */
  icon: string;
  /** Opcjonalne tło elementu */
  background?: string;
}

/**
 * Interfejs reprezentujący sekcję w panelu aplikacji.
 */
interface Section {
  /** Nazwa sekcji */
  name: string;
  /** Elementy w sekcji */
  elements: AppElement[];
}

/**
 * Komponent reprezentujący panel aplikacji.
 * Oferuje możliwość wyświetlenia sekcji z elementami aplikacji.
 */
@Component({
  selector: 'app-app-panel',
  templateUrl: './app-panel.component.html',
  styleUrls: ['./app-panel.component.css'],
  standalone: true,
  imports: [CommonModule, OverlayPanelModule, ButtonModule],
})
export class AppPanelComponent implements OnInit {

  sections: Section[] = [];

  loading = true;

  constructor(private http: HttpClient) {}

  ngOnInit() {
    // Ładowanie danych sekcji z pliku JSON
    this.http.get<Section[]>('https://static1.ekowitryna.pl/applications.json').subscribe((data: any) => {
      this.sections = data.sections;
      this.loading = false;
    });
  }
}

interface AppPanelState {
  sections?: Section[];
  loading: boolean;
  error?: string;
}