import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html'
})
export class ErrorDialogComponent {

  @Input() errorMessages: string[] | null = null;
}
