export const environment = {
    production: false,
    baseUrl: "https://esg.dev2.bdomobile.com",
    apiUrl: "https://server.dev2.bdomobile.com/",
    keycloakUrl: "https://keycloak-dev.mobiletract.com",
    realm: "bdo-dev2",
    clientId: "esg-calculator-web",
    googleTagManagerId: 'GTM-XXXXXX',
    googleTagManagerPreview: 'env-3',
    googleTagManagerAuth: 'nwRCCNlB1-SPt0eiDeFIOw'
  };