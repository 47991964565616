<div class="my-32 flex flex-col items-center justify-center text-center p-6">
  <div class="mb-8">
    <i class="pi pi-exclamation-triangle text-red-600 text-8xl"></i>
  </div>
  
  <p class="text-3xl text-gray-800 dark:text-gray-200 mb-4" *ngIf="translationsLoaded">
    {{ authorized ? ('errorPages.404.pageNotFound' | translate) : ('errorPages.unauthorized.accessDenied' | translate) }}
  </p>

  <p class="text-lg text-gray-600 dark:text-gray-100 mb-10" *ngIf="translationsLoaded">
    {{ authorized ? ('errorPages.404.pageIsntAvailable' | translate) : ('errorPages.unauthorized.noAuthorization' | translate) }}
  </p>

  <div class="flex flex-col lg:flex-row items-center gap-4 m-8" *ngIf="translationsLoaded">
    <button 
      pButton 
      type="button" 
      label="{{ 'errorPages.404.buttonText' | translate }}" 
      icon="pi pi-home" 
      class="p-button-outlined p-button-success text-lg py-4 px-8 font-semibold text-green-600 hover:bg-green-600 hover:text-white" 
      routerLink="/">
    </button>
  </div>

  <p *ngIf="!translationsLoaded" class="text-lg text-gray-600 dark:text-gray-100 mb-10">
    Ładowanie tłumaczeń...
  </p>
</div>
