import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
})
export class NavigationBarComponent {
  items: MenuItem[];
  activeItem: MenuItem;

  constructor() {
      this.items = [
          {
              label: 'Strona główna',
              icon: 'pi pi-fw pi-home',
              routerLink: ['home']
          },
          {
              label: 'Forum',
              icon: 'pi pi-fw pi-users',
              url: 'https://forum.ekowitryna.pl'
          },
          {
              label: 'KOBiZE',
              icon: 'pi pi-fw pi-globe',
              url: 'https://kobize.ekowitryna.pl'
          },
          {
              label: 'BDO mobile',
              icon: 'pi pi-fw pi-mobile',
              url: 'https://panel.bdomobile.com'
          },
          // Add more menu items as needed
      ];

      this.activeItem = this.items[0];
  }
}
