<button (click)="menu.toggle($event)" class="flex items-center focus:outline-none mx-2">
  <img [src]="'assets/flags/4x3/' + (selectedCountry === 'EN' ? 'gb' : selectedCountry.toLowerCase()) + '.svg'" 
       alt="{{ selectedCountry }}" 
       class="w-5">
</button>

<p-menu #menu [popup]="true" [model]="countries">
  <ng-template let-item pTemplate="item">
    <div class="flex items-center p-2 cursor-pointer">
      <img [src]="item.icon" alt="{{ item.label }}" class="w-5 mx-2">
      <span>{{ item.label }}</span>
    </div>
  </ng-template>
</p-menu>
