import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import packageInfo from '../../../../../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  actualYear = new Date().getFullYear();
  appVersion = packageInfo.version;

  constructor(private translate: TranslateService){

  }
}
