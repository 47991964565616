import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent implements OnInit {
  authorized: boolean = true;
  translationsLoaded: boolean = false; 

  constructor(
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loadTranslations(); 

    this.router.events.subscribe(() => {
      this.checkAuthorizationStatus();
    });

    this.checkAuthorizationStatus();
  }

  private loadTranslations(): void {
    this.translate.getTranslation(this.translate.currentLang).subscribe(() => {
      this.translationsLoaded = true; 
    });
  }

  private checkAuthorizationStatus(): void {
    const currentUrl = this.router.url;
    this.authorized = !currentUrl.includes('/unauthorized');
  }
}
