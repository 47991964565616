<div
  class="p-6 rounded-md border border-zinc-100 shadow-md dark:bg-gray-900 dark:border-zinc-700 flex flex-col relative group"
  [ngClass]="styleClass"
  #statBox
>
  <!-- Nagłówek z ikoną i tekstami -->
  <div class="flex items-center gap-4 mb-4">
    <!-- Okrągły box z ikoną -->
    <div
      class="flex items-center justify-center w-16 h-16 rounded-full shadow-lg"
      [ngClass]="iconStyleClass"
    >
      <i [class]="icon" class="text-2xl"></i>
    </div>

    <!-- Tytuł i opis -->
    <div class="flex flex-col justify-center">
      <span
        class="text-xl font-bold text-gray-900 dark:text-gray-100 leading-none"
        [ngClass]="titleStyleClass"
      >
        {{ title }}
      </span>
      <span
        class="text-sm text-gray-600 dark:text-gray-300 mt-1 leading-tight"
        *ngIf="description"
        [ngClass]="descriptionStyleClass"
      >
        {{ description }}
      </span>
    </div>
  </div>

  <!-- Wartość metryki z prefiksem i sufiksem -->
  <div
    class="text-4xl font-bold text-gray-900 dark:text-gray-100 mt-auto flex items-baseline gap-1"
    [ngClass]="valueStyleClass"
  >
    <span class="text-lg" *ngIf="prefix">{{ prefix }}</span>
    <span>{{ value }}</span>
    <span class="text-lg" *ngIf="suffix">{{ suffix }}</span>
  </div>

  <!-- Ikona pobierania -->
  <button
    class="absolute top-2 right-2 text-gray-800 dark:text-white rounded-full p-2 hover:bg-gray-300 dark:hover:bg-gray-600 opacity-0 group-hover:opacity-100 transition-opacity"
    (click)="downloadAsImage(statBox)"
  >
    <i class="pi pi-download"></i>
  </button>
</div>
