import { KeycloakProfile } from 'keycloak-js';
import { createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';
import { User } from './user.model';
export const userFeatureKey = 'user';

export interface UserState {
  user: User | null;
  error: any | null;
}

export const initialState: UserState = {
  user: null,
  error: null,
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.loadUserSuccess, (state, action) => ({
    ...state,
    user: action.user,
    error: null,
  })),
  on(UserActions.loadUserFailure, (state, action) => ({
    ...state,
    user: null,
    error: action.error,
  }))
);
