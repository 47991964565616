import { Component, Input, ElementRef, Renderer2 } from '@angular/core';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-simple-stat-box',
  templateUrl: './simple-stat-box.component.html',
})
export class SimpleStatBoxComponent {
  @Input() value!: number | string; // Wartość metryki
  @Input() prefix?: string; // Opcjonalny prefiks (np. waluta)
  @Input() suffix?: string; // Opcjonalny sufiks (np. jednostka)
  @Input() title!: string; // Tytuł metryki
  @Input() description?: string; // Opcjonalny opis
  @Input() icon!: string; // Klasa ikonki (np. PrimeIcons)
  @Input() styleClass?: string; // Dodatkowe style dla głównego kontenera
  @Input() iconStyleClass?: string; // Style dla ikonki
  @Input() valueStyleClass?: string; // Style dla wartości
  @Input() titleStyleClass?: string; // Style dla tytułu
  @Input() descriptionStyleClass?: string; // Style dla opisu

  hovering = false; // Czy kursor jest nad kafelkiem

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  downloadAsImage(element: HTMLElement): void {
    const downloadButton = element.querySelector('button');
  
    // Ukryj przycisk pobierania
    if (downloadButton) {
      this.renderer.setStyle(downloadButton, 'visibility', 'hidden');
    }
  
    // Wygeneruj obraz z ustawionymi poprawkami
    html2canvas(element, {
      scale: 2, // Skalowanie, aby poprawić jakość i interpretację layoutu
      useCORS: true, // Użyj CORS dla zewnętrznych zasobów (np. czcionki, obrazy)
      allowTaint: false, // Ignoruj błędy z zasobami
      backgroundColor: null, // Usuń tło, jeśli jest przezroczyste
    }).then((canvas) => {
      if (downloadButton) {
        this.renderer.setStyle(downloadButton, 'visibility', 'visible');
      }
  
      const link = document.createElement('a');
      link.download = `${this.title}.png`;
      link.href = canvas.toDataURL();
      link.click();
    });
  }
  
}
