<div class="w-full flex pl-2 ">
        <div class="w-full gap-2" #resizableBlock [ngClass]="{'flex': width > 320}">
            <div class="w-full" [ngClass]="{'calc-max-width': width > 320}">
                <div class="flex flex-col flex-grow gap-1 ">
                    <span class="font-medium text-sm">{{ 'views.app.data.subject' | translate }}</span>
                    <p-skeleton *ngIf="loadingCompanies$ | async" class="w-[200px]" height="3rem"></p-skeleton>
                    <p-dropdown *ngIf="(loadingCompanies$ | async) == false && companies.length == 0" class="flex-1"
                        optionLabel="name" [disabled]="true" [options]="[{ name: 'Brak podmiotów' }]"></p-dropdown>
                    <p-dropdown *ngIf="(loadingCompanies$ | async) == false && companies.length > 0"
                        [options]="companies" [ngModel]="(selectedCompany$ | async) ?? []"
                        [styleClass]="'!w-full'"
                        (ngModelChange)="onChangeCompany($event)" [filter]="true" filterBy="name">
                        <ng-template let-item pTemplate="selectedItem">
                            <div class="flex items-center gap-2">
                                <img src="https://ui-avatars.com/api/?size=128&background=random&name={{ item?.name }}"
                                    class="w-4" [alt]="item?.name" />
                                <div>{{ item?.name }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-item pTemplate="item">
                            <div class="flex items-center gap-2">
                                <img src="https://ui-avatars.com/api/?size=128&background=random&name={{ item?.name }}"
                                    class="w-4" [alt]="item?.name" />
                                <div>{{ item?.name }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
            <div  [ngClass]="{'pt-2 w-full flex': width <= 320, 'w-[110px]': width > 320}">
                <div class="flex flex-col gap-1 " [ngClass]="{'w-full': width < 320}">
                    <span class="font-medium text-sm ">{{ 'views.app.data.year' | translate }}</span>
                    <form [formGroup]="form">
                        <p-dropdown class="!w-full"
                            [options]="availableYears" formControlName="year"  (onChange)="onChangeYear($event)" styleClass="!w-full">
                        </p-dropdown>
                    </form>
                </div>
                <div class="w-[60px] text-center pt-9 " [ngClass]="{'block': width <= 320, 'hidden': width > 320}">
                    <a href="https://panel.bdomobile.com/dashboard/companies" pTooltip="{{ 'views.app.manageEntities' | translate }}" tooltipPosition="top">
                        <i class="pi pi-cog"  style="font-size: 2rem"></i>
                    </a>
                </div>    
            </div>
            <div class="w-[40px] text-center pt-9" [ngClass]="{'hidden': width <= 320, 'block': width > 320}">
                <a href="https://panel.bdomobile.com/dashboard/companies" pTooltip="{{ 'views.app.manageEntities' | translate }}" tooltipPosition="top">
                    <i class="pi pi-cog"  style="font-size: 2rem"></i>
                </a>
            </div>            
    </div>
</div>