<ul class="flex flex-col">
  <li
    *ngFor="let item of items"
    [class.hidden]="item.visible === false"
    [ngClass]="item.styleClass"
  >
    <!-- Single menu item -->
    <a
      *ngIf="!item.items; else hasChildrenTemplate"
      [routerLink]="item.disabled === true ? null : item.routerLink"
      class="flex items-center p-2 rounded-xl hover:text-white hover:bg-gray-900 mx-2 cursor-pointer transition duration-300 ease-in-out"
      [ngClass]="{
        'bg-black': item.isActive,
        'text-gray-500 cursor-not-allowed': item.disabled
      }"
      pTooltip="{{ item.tooltip }}"
      [tooltipPosition]="item.tooltipPosition || 'right'"
    >
      <div
        class="flex bg-gray-900 rounded-xl w-14 h-14 items-center justify-center dark:text-white text-gray-900 hover:tex"
        [ngClass]="{
          'bg-gray-900 text-white-50 dark:bg-gray-600': isParentMenuOptionActive(item),
          'bg-gray-700 dark:bg-gray-500': !isParentMenuOptionActive(item),
          'text-gray-400 hover:text-stone-600 cursor-not-allowed': item.disabled,
        }"
      >
        <i [ngClass]="item.icon" class="text-2xl text-white"></i>
      </div>
      <span class="ml-2 ">{{ item.label }}</span>
      <span
        *ngIf="item.badge"
        class="ml-auto badge bg-blue-500 text-white px-2 py-1 rounded-full text-[8px] lg:text-xs"
        [ngClass]="item.badgeClass"
        >{{ item.badge }}</span
      >
    </a>

    <!-- Menu item with children -->
    <ng-template #hasChildrenTemplate>
      <div
        class="flex items-center p-2 rounded-xl dark:text-gray-200 hover:bg-gray-900 text-gray-700 hover:text-white mx-2 cursor-pointer  transition duration-300 ease-in-out"
        (click)="item.expanded = !item.expanded"
        (keyup)="item.expanded = !item.expanded"
        tabindex="0"
      >
        <div
          class="flex rounded-xl w-14 h-14 items-center justify-center"
          [ngClass]="{
            'bg-gray-900 dark:bg-gray-600 text-white-50': isParentMenuOptionActive(item),
            'bg-gray-700 dark:bg-gray-500': !isParentMenuOptionActive(item),
            'text-gray-300 hover:text-gray-300 dark:text-gray-200 hover:!bg-transparent cursor-not-allowed': item.disabled,
          }"
          pTooltip="{{ item.tooltip }}"
          [tooltipPosition]="item.tooltipPosition || 'right'"
        >
          <i [ngClass]="item.icon" class="text-2xl text-white"></i>
        </div>
        <span class="ml-2">{{ item.label }}</span>
        <span
        *ngIf="item.badge"
        class="ml-auto badge bg-blue-500 text-white px-2 py-1 rounded-full text-[8px] lg:text-xs"
        [ngClass]="item.badgeClass"
        >{{ item.badge }}</span
      >
        <i
          class="ml-auto transition-transform duration-300 pi "
          [ngClass]="
            item.expanded ? 'pi-chevron-right rotate-90' : 'pi-chevron-right'
          "
        ></i>
      </div>
      <ul
        class="ml-8 overflow-hidden transition-max-height duration-700 ease-in-out"
        [style.max-height]="item.expanded ? '500px' : '0'"
      >
        <li *ngFor="let child of item.items">
          <a
            [routerLink]="child.routerLink"
            routerLinkActive="border-gray-900"
            [routerLinkActiveOptions]="child.routerLinkActiveOptions || { exact: false }"
            class="flex items-center p-2 border-l-2 dark:text-gray-300 border-gray-300 dark:border-gray-200 hover:bg-gray-800 dark:hover:text-pigment-green-800 dark:text-gray-200 hover:text-white transition duration-300 ease-in-out"
            [ngClass]="{
              'text-gray-300 hover:text-gray-300 hover:!bg-transparent cursor-not-allowed': child.disabled,
            }"
            pTooltip="{{ child.tooltip }}"
            [tooltipPosition]="child.tooltipPosition || 'right'"
          >
            <i [ngClass]="child.icon" class="text-lg"></i>
            <span class="ml-2 text-md">{{ child.label }}</span>
          </a>
          <span
          *ngIf="child.badge"
          class="ml-auto badge bg-blue-500 text-white px-2 py-1 rounded-full text-[8px] lg:text-xs"
          [ngClass]="child.badgeClass"
          >{{ child.badge }}</span
        >
        </li>
      </ul>
    </ng-template>
  </li>
</ul>
