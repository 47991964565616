import { Injectable } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PrimengTranslationService {

  constructor(private config: PrimeNGConfig, private translateService: TranslateService) {
    this.setTranslations();
  }

  public setTranslations() {
    // Pobranie tłumaczeń z TranslateService
    this.translateService.get([
      'primeng.common.accept',
      'primeng.common.reject',
      'primeng.filters.startsWith',
      'primeng.filters.contains',
      'primeng.filters.notContains',
      'primeng.filters.endsWith',
      'primeng.filters.equals',
      'primeng.filters.notEquals',
      'primeng.filters.noFilter',
      'primeng.filters.lt',
      'primeng.filters.lte',
      'primeng.filters.gt',
      'primeng.filters.gte',
      'primeng.filters.is',
      'primeng.filters.isNot',
      'primeng.filters.before',
      'primeng.filters.after',
      'primeng.filters.dateIs',
      'primeng.filters.dateIsNot',
      'primeng.filters.dateBefore',
      'primeng.filters.dateAfter',
      'primeng.filters.clear',
      'primeng.filters.apply',
      'primeng.filters.matchAll',
      'primeng.filters.matchAny',
      'primeng.filters.addRule',
      'primeng.filters.removeRule',
      'primeng.buttons.choose',
      'primeng.buttons.upload',
      'primeng.buttons.cancel',
      'primeng.dates.dayNames',
      'primeng.dates.dayNamesShort',
      'primeng.dates.dayNamesMin',
      'primeng.dates.monthNames',
      'primeng.dates.monthNamesShort',
      'primeng.dates.dateFormat',
      'primeng.dates.firstDayOfWeek',
      'primeng.dates.today',
      'primeng.dates.weekHeader',
      'primeng.password.weak',
      'primeng.password.medium',
      'primeng.password.strong',
      'primeng.password.passwordPrompt',
      'primeng.messages.emptyMessage',
      'primeng.messages.emptyFilterMessage',
      'primeng.messages.pending',
      'primeng.messages.chooseYear',
      'primeng.messages.chooseMonth',
      'primeng.messages.chooseDate',
      'primeng.messages.prevDecade',
      'primeng.messages.nextDecade',
      'primeng.messages.prevYear',
      'primeng.messages.nextYear',
      'primeng.messages.prevMonth',
      'primeng.messages.nextMonth',
      'primeng.messages.prevHour',
      'primeng.messages.nextHour',
      'primeng.messages.prevMinute',
      'primeng.messages.nextMinute',
      'primeng.messages.prevSecond',
      'primeng.messages.nextSecond',
      'primeng.messages.am',
      'primeng.messages.pm',
      'primeng.messages.fileSizeTypes',
      'primeng.messages.searchMessage',
      'primeng.messages.selectionMessage',
      'primeng.messages.emptySelectionMessage',
      'primeng.messages.emptySearchMessage',
      'primeng.aria.trueLabel',
      'primeng.aria.falseLabel',
      'primeng.aria.nullLabel',
      'primeng.aria.star',
      'primeng.aria.stars',
      'primeng.aria.selectAll',
      'primeng.aria.unselectAll',
      'primeng.aria.close',
      'primeng.aria.previous',
      'primeng.aria.next',
      'primeng.aria.navigation',
      'primeng.aria.scrollTop',
      'primeng.aria.moveTop',
      'primeng.aria.moveUp',
      'primeng.aria.moveDown',
      'primeng.aria.moveBottom',
      'primeng.aria.moveToTarget',
      'primeng.aria.moveToSource',
      'primeng.aria.moveAllToTarget',
      'primeng.aria.moveAllToSource',
      'primeng.aria.pageLabel',
      'primeng.aria.firstPageLabel',
      'primeng.aria.lastPageLabel',
      'primeng.aria.nextPageLabel',
      'primeng.aria.prevPageLabel',
      'primeng.aria.rowsPerPageLabel',
      'primeng.aria.jumpToPageDropdownLabel',
      'primeng.aria.jumpToPageInputLabel',
      'primeng.aria.selectRow',
      'primeng.aria.unselectRow',
      'primeng.aria.expandRow',
      'primeng.aria.collapseRow',
      'primeng.aria.showFilterMenu',
      'primeng.aria.hideFilterMenu',
      'primeng.aria.filterOperator',
      'primeng.aria.filterConstraint',
      'primeng.aria.editRow',
      'primeng.aria.saveEdit',
      'primeng.aria.cancelEdit',
      'primeng.aria.listView',
      'primeng.aria.gridView',
      'primeng.aria.slide',
      'primeng.aria.slideNumber',
      'primeng.aria.zoomImage',
      'primeng.aria.zoomIn',
      'primeng.aria.zoomOut',
      'primeng.aria.rotateRight',
      'primeng.aria.rotateLeft'
    ]).subscribe(translations => {
      this.config.setTranslation({
        // Przypisanie tłumaczeń do obiektów konfiguracji PrimeNG
        accept: translations['primeng.common.accept'],
        reject: translations['primeng.common.reject'],
        // Tłumaczenia dla filtrów
        startsWith: translations['primeng.filters.startsWith'],
        contains: translations['primeng.filters.contains'],
        notContains: translations['primeng.filters.notContains'],
        endsWith: translations['primeng.filters.endsWith'],
        equals: translations['primeng.filters.equals'],
        notEquals: translations['primeng.filters.notEquals'],
        noFilter: translations['primeng.filters.noFilter'],
        lt: translations['primeng.filters.lt'],
        lte: translations['primeng.filters.lte'],
        gt: translations['primeng.filters.gt'],
        gte: translations['primeng.filters.gte'],
        is: translations['primeng.filters.is'],
        isNot: translations['primeng.filters.isNot'],
        before: translations['primeng.filters.before'],
        after: translations['primeng.filters.after'],
        dateIs: translations['primeng.filters.dateIs'],
        dateIsNot: translations['primeng.filters.dateIsNot'],
        dateBefore: translations['primeng.filters.dateBefore'],
        dateAfter: translations['primeng.filters.dateAfter'],
        clear: translations['primeng.filters.clear'],
        apply: translations['primeng.filters.apply'],
        matchAll: translations['primeng.filters.matchAll'],
        matchAny: translations['primeng.filters.matchAny'],
        addRule: translations['primeng.filters.addRule'],
        removeRule: translations['primeng.filters.removeRule'],
        // Tłumaczenia dla przycisków
        choose: translations['primeng.buttons.choose'],
        upload: translations['primeng.buttons.upload'],
        cancel: translations['primeng.buttons.cancel'],
        // Tłumaczenia dla dat
        dayNames: translations['primeng.dates.dayNames'],
        dayNamesShort: translations['primeng.dates.dayNamesShort'],
        dayNamesMin: translations['primeng.dates.dayNamesMin'],
        monthNames: translations['primeng.dates.monthNames'],
        monthNamesShort: translations['primeng.dates.monthNamesShort'],
        dateFormat: translations['primeng.dates.dateFormat'],
        firstDayOfWeek: translations['primeng.dates.firstDayOfWeek'],
        today: translations['primeng.dates.today'],
        weekHeader: translations['primeng.dates.weekHeader'],
        // Tłumaczenia dla siły hasła
        weak: translations['primeng.password.weak'],
        medium: translations['primeng.password.medium'],
        strong: translations['primeng.password.strong'],
        passwordPrompt: translations['primeng.password.passwordPrompt'],
        // Tłumaczenia dla komunikatów
        emptyMessage: translations['primeng.messages.emptyMessage'],
        emptyFilterMessage: translations['primeng.messages.emptyFilterMessage'],
        pending: translations['primeng.messages.pending'],
        chooseYear: translations['primeng.messages.chooseYear'],
        chooseMonth: translations['primeng.messages.chooseMonth'],
        chooseDate: translations['primeng.messages.chooseDate'],
        prevDecade: translations['primeng.messages.prevDecade'],
        nextDecade: translations['primeng.messages.nextDecade'],
        prevYear: translations['primeng.messages.prevYear'],
        nextYear: translations['primeng.messages.nextYear'],
        prevMonth: translations['primeng.messages.prevMonth'],
        nextMonth: translations['primeng.messages.nextMonth'],
        prevHour: translations['primeng.messages.prevHour'],
        nextHour: translations['primeng.messages.nextHour'],
        prevMinute: translations['primeng.messages.prevMinute'],
        nextMinute: translations['primeng.messages.nextMinute'],
        prevSecond: translations['primeng.messages.prevSecond'],
        nextSecond: translations['primeng.messages.nextSecond'],
        am: translations['primeng.messages.am'],
        pm: translations['primeng.messages.pm'],
        fileSizeTypes: translations['primeng.messages.fileSizeTypes'],
        searchMessage: translations['primeng.messages.searchMessage'],
        selectionMessage: translations['primeng.messages.selectionMessage'],
        emptySelectionMessage: translations['primeng.messages.emptySelectionMessage'],
        emptySearchMessage: translations['primeng.messages.emptySearchMessage'],
        // Tłumaczenia dla ARIA
        aria: {
          trueLabel: translations['primeng.aria.trueLabel'],
          falseLabel: translations['primeng.aria.falseLabel'],
          nullLabel: translations['primeng.aria.nullLabel'],
          star: translations['primeng.aria.star'],
          stars: translations['primeng.aria.stars'],
          selectAll: translations['primeng.aria.selectAll'],
          unselectAll: translations['primeng.aria.unselectAll'],
          close: translations['primeng.aria.close'],
          previous: translations['primeng.aria.previous'],
          next: translations['primeng.aria.next'],
          navigation: translations['primeng.aria.navigation'],
          scrollTop: translations['primeng.aria.scrollTop'],
          moveTop: translations['primeng.aria.moveTop'],
          moveUp: translations['primeng.aria.moveUp'],
          moveDown: translations['primeng.aria.moveDown'],
          moveBottom: translations['primeng.aria.moveBottom'],
          moveToTarget: translations['primeng.aria.moveToTarget'],
          moveToSource: translations['primeng.aria.moveToSource'],
          moveAllToTarget: translations['primeng.aria.moveAllToTarget'],
          moveAllToSource: translations['primeng.aria.moveAllToSource'],
          pageLabel: translations['primeng.aria.pageLabel'],
          firstPageLabel: translations['primeng.aria.firstPageLabel'],
          lastPageLabel: translations['primeng.aria.lastPageLabel'],
          nextPageLabel: translations['primeng.aria.nextPageLabel'],
          prevPageLabel: translations['primeng.aria.prevPageLabel'],
          rowsPerPageLabel: translations['primeng.aria.rowsPerPageLabel'],
          jumpToPageDropdownLabel: translations['primeng.aria.jumpToPageDropdownLabel'],
          jumpToPageInputLabel: translations['primeng.aria.jumpToPageInputLabel'],
          selectRow: translations['primeng.aria.selectRow'],
          unselectRow: translations['primeng.aria.unselectRow'],
          expandRow: translations['primeng.aria.expandRow'],
          collapseRow: translations['primeng.aria.collapseRow'],
          showFilterMenu: translations['primeng.aria.showFilterMenu'],
          hideFilterMenu: translations['primeng.aria.hideFilterMenu'],
          filterOperator: translations['primeng.aria.filterOperator'],
          filterConstraint: translations['primeng.aria.filterConstraint'],
          editRow: translations['primeng.aria.editRow'],
          saveEdit: translations['primeng.aria.saveEdit'],
          cancelEdit: translations['primeng.aria.cancelEdit'],
          listView: translations['primeng.aria.listView'],
          gridView: translations['primeng.aria.gridView'],
          slide: translations['primeng.aria.slide'],
          slideNumber: translations['primeng.aria.slideNumber'],
          zoomImage: translations['primeng.aria.zoomImage'],
          zoomIn: translations['primeng.aria.zoomIn'],
          zoomOut: translations['primeng.aria.zoomOut'],
          rotateRight: translations['primeng.aria.rotateRight'],
          rotateLeft: translations['primeng.aria.rotateLeft']
        }
      });
    });
  }
}
